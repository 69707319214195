import { css, Global } from "@emotion/react";
import { theme } from "./AppTheme";
import useMediaQuery from "Application/Hooks/useMediaQuery";

import smallBackground from "../Assets/LayoutBackground.png";
import largeBackground from "../Assets/LayoutBackground@2x.png";

export const GlobalStyles = () => {
    const scrollbar_backgroundcolor = theme.palette.secondary.light;
    const scrollbar_backgroundbordercolor = "#DCDCDC";
    const isMobile = useMediaQuery("(max-width: 767px)");
    const layoutBackgroundURL = isMobile ? smallBackground : largeBackground;
    return (
        <Global
            styles={css`
                html,
                body {
                    margin: 0;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    text-rendering: optimizeLegibility;
                    background-color: white !important;
                    height: 100%;
                    z-index: 0;

                    font-family: ${theme.defaultFontStyle.fontFamily};
                    font-size: ${theme.defaultFontStyle.fontSize};
                    line-height: ${theme.defaultFontStyle.lineHeight};

                    overflow: hidden;
                    @media only screen and (max-width: 991px) {
                        background-color: #cc0000;
                    }
                }
                code {
                    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
                }
                .overflowContainer {
                    &--default {
                        position: relative;
                        overflow: auto;
                        height: 100vh;
                        //header size buffer
                        padding-top: 50px;
                        z-index: 2;
                        /* overflow-x: hidden; */
                    }

                    &--no-footer {
                        position: relative;
                        overflow: auto;
                        height: 100vh;
                    }
                    &--no-header-no-footer {
                        position: relative;
                        overflow: auto;
                        height: 100vh;
                    }
                    &--split-layout {
                        position: relative;
                        overflow: auto;
                        height: 100vh;
                    }
                }
                .backgroundContainer {
                    position: absolute;
                    z-index: -1;
                    background-image: ${`url(${layoutBackgroundURL})`};
                    background-position: center;
                    background-repeat: no-repeat;
                    background-position: top;
                    background-size: cover;
                    content: "";
                    height: 100px;
                    width: 100%;
                    min-width: 100%;
                }
                /////////////////////////////////////////
                //Custom scroll bar
                * {
                    scrollbar-width: thin;
                    scrollbar-color: ${scrollbar_backgroundcolor} ${scrollbar_backgroundbordercolor};
                }

                // Chrome, Edge, and Safari - more overrides available, including radius, width and thumb borders.
                *::-webkit-scrollbar {
                    height: 9px;
                    width: 9px;
                }

                *::-webkit-scrollbar-track {
                    background: ${scrollbar_backgroundbordercolor};
                }

                *::-webkit-scrollbar-thumb {
                    background-color: ${scrollbar_backgroundcolor};
                    border-radius: 9px;
                    border: 1px solid ${scrollbar_backgroundbordercolor};
                }
                //End of custom scroll bar
                /////////////////////////////////////////

                /////////////////////////////////////////
                //Remove blue background from inputs
                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px white inset !important;
                }
                /////////////////////////////////////////
            `}
        />
    );
};
