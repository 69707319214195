import React from "react";
import { observer } from "mobx-react-lite";
import { Box, CheckBox, ErrorExclamationView, Label } from "Application";
import { ButtonGroup, Container, Main, Reference, ReferenceInnerContainer, SessionReportContainer, TargetDescription } from "./StudentSubTargetView.styles";
import { StudentSubTargetViewModel } from "./StudentSubTargetViewModel";
import { ShowHideButton } from "Views/Students/Forms/StudentTargets/Shared/Components/ShowHideButton";
import classNames from "classnames";
import { WarningModal } from "Views/Students/Shared/WarningModal";
import { StudentSessionSubTargetView } from "../StudentSessionSubTarget/StudentSessionSubTargetView";

interface IStudentSubTargetViewProps {
    index?: number;
    viewModel: StudentSubTargetViewModel;
}
//currently this view only adds a session report
export const StudentSubTargetView: React.FC<IStudentSubTargetViewProps> = observer(({ index, viewModel }) => {
    const backgroundColour = index ? viewModel.backgroundColour(index) : undefined;
    const { isMobile } = viewModel;
    return (
        <>
            <Container backgroundColour={backgroundColour} className={classNames({ isMobile })}>
                <Reference>
                    <ReferenceInnerContainer>
                        <Label fontStyle="h4">{viewModel.reference}</Label>
                        <Box ml={"-5px"}>
                            <ErrorExclamationView haveError={viewModel.showError} validationMessage={() => "The sub target session report has errors"} />
                        </Box>
                    </ReferenceInnerContainer>
                </Reference>

                <Main className={classNames({ isMobile })}>
                    <TargetDescription className={classNames({ show: viewModel.showStudentSessionSubTarget, isMobile })}>
                        <Label>{viewModel.description}</Label>
                    </TargetDescription>

                    {isMobile && (
                        <>
                            <ButtonGroup className={classNames({ isMobile })}>
                                <ShowHideButton command={viewModel.toggleShowStudentSessionSubTargetCommand} show={viewModel.showStudentSessionSubTarget} />
                                <Box mt="2px">
                                    <CheckBox command={viewModel.updateIsCompletedByMentorCommand} value={() => viewModel.isCompleted} />
                                </Box>
                            </ButtonGroup>
                        </>
                    )}
                    {!isMobile && (
                        <>
                            <SessionReportContainer className={classNames({ show: viewModel.showStudentSessionSubTarget })}>
                                <StudentSessionSubTargetView viewModel={viewModel.studentSessionSubTargetViewModel!} />
                            </SessionReportContainer>
                        </>
                    )}
                </Main>

                {!isMobile && (
                    <>
                        <ButtonGroup>
                            <ShowHideButton command={viewModel.toggleShowStudentSessionSubTargetCommand} show={viewModel.showStudentSessionSubTarget} />
                            <Box mt="2px">
                                <CheckBox command={viewModel.updateIsCompletedByMentorCommand} value={() => viewModel.isCompleted} />
                            </Box>
                        </ButtonGroup>
                    </>
                )}
            </Container>
            {isMobile && (
                <SessionReportContainer backgroundColour={backgroundColour} className={classNames({ show: viewModel.showStudentSessionSubTarget, isMobile })}>
                    <StudentSessionSubTargetView viewModel={viewModel.studentSessionSubTargetViewModel!} />
                </SessionReportContainer>
            )}
            {/* Deletion warning modal */}
            <WarningModal
                isOpen={viewModel.showMarkAsIncompleteWarning}
                onClose={viewModel.dismissMarkAsIncompleteWarningCommand}
                onConfirm={viewModel.confirmMarkAsIncompleteCommand}
                warningMessage="Marking this as incomplete will prevent the sub target session report from being added to the session report. Are you sure you want to continue?"
            />
        </>
    );
});
