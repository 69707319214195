import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Header } from "Views/Reports/Components/Header";
import { Form } from "Views/Reports/Components/Form";
import { ReportsViewModel } from "./ReportsViewModel";
import { ApplicationError } from "Components";
export const ReportsView: React.FC = observer(() => {
    const [viewModel] = useState(new ReportsViewModel());

    useEffect(() => {
        viewModel.initialize();
        return () => {
            viewModel.reset();
        };
    }, []);

    return (
        <>
            <Header title="Reports" />
            <Form viewModel={viewModel} />
            {viewModel.apiClient.IsSubmitted && viewModel.apiClient.HaveValidationMessage && (
                <div className="error-scrollable-section">
                    <div>
                        <ApplicationError additionalMessage={viewModel.apiClient.ValidationMessage} resetCommand={viewModel.resetApiClientErrorCommand} />
                    </div>
                </div>
            )}
        </>
    );
});
