//#region Class/Component Definition
export class GlobalAppUrls {
    //#region Properties & State
    public Client = {
        Account: {
            ConfirmEmail: "/account/confirmemail/:token",
            ForgotPassword: "/account/forgotpassword",
            ForgotPasswordSuccess: "/account/forgotpassword-success",
            Login: "/account/login",
            Logout: "/logout",

            Register: "/account/register",
            RegisterSuccess: "/account/register/success",
            ResetPassword: "/account/resetpassword/:token",
            SessionExpired: "/sessionexpired",
        },
        Admin: {
            AdminHome: "/admin/dashboard/userslist",
            LogViewer: "/admin/logviewer",
            Mentor: {
                Resources: "/mentor/resources",
                Root: "/mentor",
                WeeklyRota: "/mentor/weeklyrota",
            },
        },
        Attendance: {
            Home: "/attendance",
        },
        ColoursMUI: "/coloursmui",
        DashBoard: "/dashboard",
        Demo: "/application/personalinfo",
        Error: "/error",
        Home: "/",
        Mentors: {
            MentorDetail: {
                Edit: "/mentors/mentor/:id",
                Root: "mentors/mentor",
            },
            Root: "/mentors",
        },
        NotFound: "/notfound",
        PageBuilder: "/pagebuilder",
        PrivacyPolicy: "/privacy-policy",
        Reports: {
            Root: "/reports/*",
            Home: "/reports",
        },
        Resources: {
            Home: "/resources",
        },
        Samples: "/samples/text",
        Students: {
            Current: "/students/live",
            Enquiry: "/students/enquiry",
            Outreach: "/students/outreach",
            Past: "/students/past",
            Root: "/students",
            Student: {
                StudentCare: {
                    Edit: "/students/care/:id",
                    Root: "/students/care",
                },

                StudentDetail: {
                    Edit: "/students/student/:id",
                    Root: "/students/student",
                },
                StudentDocuments: {
                    Edit: "/students/documents/:id",
                    Root: "/students/documents",
                },
                StudentReports: {
                    Add: "/students/reports/report/:reportId",
                    Edit: "/students/reports/:id",
                    New: "/students/reports/:studentId/report/new",
                    Root: "/students/reports",
                },
                StudentTargets: {
                    Active: "/students/targets/:id/active",
                    Completed: "/students/targets/:id/completed",
                    Edit: "/students/targets/:id",
                    EditTarget: "/students/targets/:id/edit/:targetId",
                    Root: "/students/targets",
                },
                StudentTimeTable: {
                    Edit: "/students/timetable/:id",
                    Root: "/students/timetable",
                },
            },
        },

        StyleSheet: "/stylesheet",
        Terms: "/terms",
        Test: "/test",
        Theme: "/theme",
        Todo: "/todo",
        WeeklyRota: {
            AddWeeklyRotaReport: "/mentor/weeklyrota/student/:studentId/mentor/:mentorId/report/:id",
            DetailsWeeklyRotaReport: "/mentor/weeklyrota/reports/report/:reportId",
            Home: "/weeklyrota",
            WeeklyRotaDetail: {
                Edit: "/weeklyrota/:id",
            },
        },

        Blank: "/blank",
    };

    public Server = {
        Account: {
            ForgotPassword: "/api/account/forgotpassword",
            Login: "/api/account/login",
            Logout: "/api/account/logout",
            Register: "/api/account/register",
            ResendEmailConfirmation: "api/account/resendemailconfirmation",
            ResetPassword: {
                Reset: "/api/account/resetpassword",
                VerifyToken: "/api/account/resetpasswordtoken",
            },
            SetPassword: "/api/account/setpassword",

            CreateNewPassword: "/api/account/createnewpassword",
            User: {
                DeleteRole: "/api/role/delete",
                DeleteUser: "/api/user/delete",
            },
        },
        Admin: {
            GetRoles: "/api/roles/getall",
            ResetFailedLoginAttemptsCount: "/api/user/ResetLoginAttempts",
        },
        Attendance: {
            GetAttendanceReport: "/api/student/getattendancereport",
            GetAttendanceReportAndRelated: "/api/student/getattendancereportandrelated",
        },
        InitialState: {
            Initialize: "/api/initialstate",
        },
        Mentor: {
            DeleteMentorById: "/api/mentor/deletementor/:id",
            GetAllMentors: "/api/mentor/getallmentorslite",
            GetMentorById: "/api/mentor/getmentordetailsbyid/:id",
            SaveMentor: "/api/mentor/savementor",
        },
        MentorRole: {
            EditStudentAttendend: "/api/student/editstudentsession",
            GetAllMentors: "/api/mentor/getallmentorslite",
            GetMentorsSessionReports: "/api/mentor/getallmentorsessionreport/:startdate",
        },
        Resources: {
            GetAllResourceDocuments: "/api/resource/getallresourcedocuments",
            GetResourceDocumentForDownloadById: "/api/resource/getresourcedocumentfordownloadbyid/:id",
            PostDeleteResourceDocumentById: "/api/resource/deleteresourcedocumentbyid/:id",
            PostInsertResourceDocument: "/api/resource/insertresourcedocument",
        },
        Reports: {
            ExportStudentProgressReport: "/api/report/exportstudentprogressreport",
        },
        Student: {
            DeleteStudentById: "/api/student/deletestudent/:id",
            GetAllStudents: "/api/student/getallstudentslite",
            GetFilteredStudentList: "/api/student/getfilteredstudentlist",
            GetStudentDetailsById: "/api/student/getstudentdetailsbyid/:id",
            GetStudentsCount: "/api/student/getstudentscount",
            PostSaveStudent: "/api/student/savestudent",
            GetFilteredStudentKeyValuePairs: "/api/student/getfilteredstudentkeyvaluepairs",
            StudentCare: {
                GetStudentCareById: "/api/student/getstudentcarebyid/:id",
                PostSaveStudentCare: "/api/student/savestudentcare",
            },
            StudentDocuments: {
                GetAllStudentDocumentsLite: "/api/student/getallstudentdocumentslite/:id",
                GetStudentDocument: "/api/student/getstudentdocumentbyid/:id",
                PostSaveStudentDocument: "/api/student/savestudentdocument",
                SoftDeleteStudentDocumentById: "/api/student/deletestudentdocument/:id",
            },
            StudentSessions: {
                GetAllStudentSessionReportsLite: "/api/student/getallstudentSessionReportslite/:id/{startdate}/{enddate}",
                GetStudentDocumentForDownloadById: "/api/student/getstudentsessiondocumentbyid/:id",
                GetStudentSessionReport: "/api/student/getstudentsessionreport/:id",
                GetStudentSessionReportById: "/api/student/getstudentSessionReportbyid/:id",
                PostSaveSessionActivity: "/api/student/saveactivity",
                PostSaveStudentSession: "/api/student/savestudentsession",
                PostSaveStudentSessionDocument: "/api/student/savestudentsessiondocument",
            },
            StudentSubTarget: {
                Delete: "/api/student/deletestudentsubtarget",
                Insert: "/api/student/insertstudentsubtarget",
                UpdateCompletedStatus: "/api/student/updatestudentsubtargetcompletedstatus",
                Update: "/api/student/updatestudentsubtarget",
            },
            StudentTarget: {
                Delete: "/api/student/deletestudenttarget",
                EditStudentTarget: "/api/student/editstudenttargetdetails",
                GetStudentTargetById: "/api/student/getstudenttargetbyid/:id",
                GetStudentTargetDetailsById: "/api/student/getstudenttargetdetailsbyid/:id",
                Insert: "/api/student/insertstudenttarget",
                PostSaveStudentTarget: "/api/student/savestudenttarget",
                UpdateCompletedStatus: "/api/student/updatestudenttargetcompletedstatus",
                UpdateDescription: "/api/student/updatestudenttargetdescription",
            },

            Targets: {
                GetActiveStudentTargetsAndRelated: "/api/student/getactivestudenttargetsandrelated/:id",
                GetCompletedStudentTargetsAndRelated: "/api/student/getcompletedstudenttargetsrelated/:id",
                GetNextAvailableStudentTargetReference: "/api/student/getnextavailablestudenttargetreference/:id",
            },
            TimeTable: {
                GetStudentRotaById: "/api/student/getstudentrotabyid/:id",
                PostSaveStudentRota: "/api/student/savestudentrota",
            },
        },

        WeeklyRota: {
            DeleteMentorRotaAbsence: "/api/rota/deletementorrotaabsense",
            DeleteMentorStudent: "/api/rota/deletementorstudentrota",
            GetAllLeftStudentSession: "/api/student/getallleftstudentsession/:startdate",
            GetMentorStudentRotaById: "/api/rota/getmentorstudentrotabyid/:mentorStudentRotaId",
            GetWeeklyRotaByDate: "/api/rota/getrota/:startdate",
            ResetRota: "/api/rota/resetrota/:startdate",
            SaveMentorRotaAbsence: "/api/rota/savementorrotaabsense",
            SaveMentorStudentRota: "/api/rota/savementorstudentrota",
            SaveRota: "/api/rota/saverota",
        },
    };
    //#endregion
}
//#endregion

//#region Exports
export const AppUrls = new GlobalAppUrls();
//#endregion
