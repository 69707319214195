import React, { useState } from "react";
import { Box, Label, Input, theme, ErrorExclamationView } from "Application";
import { observer } from "mobx-react-lite";
import { ErrorContainer, ScoreContainer, Container } from "./SessionSessionSubTargetView.styles";
import { StudentSessionSubTargetViewModel } from "./StudentSessionSubTargetViewModel";
import { FormField } from "../../../Students/Forms/StudentReports/Form/SessionReportView.styles";
import classNames from "classnames";

//currently this view only adds a session report sub target
export const StudentSessionSubTargetView: React.FC<{ viewModel: StudentSessionSubTargetViewModel }> = observer(({ viewModel }) => {
    const { isMobile } = viewModel;

    return (
        <Container className={classNames({ isMobile: isMobile })}>
            <FormField>
                <Box position="relative" display="inline-block">
                    <Label fontStyle="label">Plan for session details*</Label>
                    <ErrorContainer>
                        <ErrorExclamationView haveError={viewModel.showSessionPlanError} validationMessage={() => viewModel.getError("sessionPlan")} />
                    </ErrorContainer>
                </Box>
                <Input
                    multiline={true}
                    displayName={"(What personal target(s) was the session working towards? Activities completed? AQA progress?)"}
                    value={() => viewModel.getValue("sessionPlan")}
                    command={viewModel.updateSessionPlanCommand}
                    placeholder={"Please add notes..."}
                    rows={4}
                    isRequired
                    showClearText={!isMobile}
                />
            </FormField>
            <FormField>
                <Box position="relative" display="inline-block">
                    <Label fontStyle="label">Sub target details*</Label>
                    <ErrorContainer>
                        <ErrorExclamationView haveError={viewModel.showDetailError} validationMessage={() => viewModel.getError("detail")} />
                    </ErrorContainer>
                </Box>

                <Input
                    multiline={true}
                    displayName="(How effective was the session in working towards personal targets?)"
                    value={() => viewModel.getValue("detail")}
                    command={viewModel.updateDetailCommand}
                    placeholder={"Please add notes..."}
                    rows={4}
                    isRequired
                    showClearText={!isMobile}
                />
            </FormField>
            <FormField>
                <Box position="relative" display="inline-block">
                    <Label fontStyle="label">Mentoring details*</Label>
                    <ErrorContainer>
                        <ErrorExclamationView haveError={viewModel.showMentoringError} validationMessage={() => viewModel.getError("mentoring")} />
                    </ErrorContainer>
                </Box>

                <Input
                    multiline={true}
                    displayName="(Actions for next session? Risk assessment/behaviour plan need updating? Any minor concerns risen from the session? Concerning comments?)"
                    value={() => viewModel.getValue("mentoring")}
                    command={viewModel.updateMentoringCommand}
                    placeholder={"Please add notes..."}
                    rows={4}
                    isRequired
                    showClearText={!isMobile}
                />
            </FormField>
            <FormField>
                <Box position="relative" display="inline-block">
                    <Label fontStyle="label">Score*</Label>
                    <ErrorContainer>
                        <ErrorExclamationView haveError={viewModel.showScoreError} validationMessage={() => viewModel.getError("score")} />
                    </ErrorContainer>
                </Box>
                <ScoreContainer>
                    <Input noHeader maxLength={2} isRequired={false} value={() => viewModel.score} command={viewModel.updateScoreCommand} showClearText={false} />
                    <Box position="absolute" top="50%" right="12px" style={{ transform: "translateY(-51%)" }}>
                        <Label fontStyle="field">/10</Label>
                    </Box>
                </ScoreContainer>
            </FormField>
        </Container>
    );
});
