/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef } from "react";

import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { Label } from "Components";
import { TransparentButton } from "./TransparentButton";
import { CloseIcon } from "./CloseIcon";
import { ICommand, theme } from "Application";
import DefaultIcon from "../../../Assets/Artboard.png";

export interface IProps {
    //summary: boolean value used to toggle show/hide the html dialog
    isOpen: boolean;
    //summary: function called on html dialog close
    onCloseCommand: ICommand;
    //summary: option boolean value that determines if the dialog can close if clicking outside of the dialog
    //note:    default value = true
    closeOnClickOutside?: boolean;
    //summary: option boolean value that determines if the close icon button is displayed
    //note:    default value = true
    showCloseIconButton?: boolean;
    //summary: optional title prop used to display the content of the modal title
    children: React.ReactNode;
    //summary: optional title prop used to display the content of the modal title
    title?: string;
    //summary: optional icon prop used to display the content of the modal title
    icon?: string;
    //summary: optional boolean value used to toggle the display of the icon
    hideIcon?: boolean;
}

const StyledDialog = styled.dialog`
    max-width: 400px;
    width: calc(100% - 50px);
    padding: 10px;
    background: ${theme.palette.common.white};
    box-shadow: 0 0 5px rgba(101, 110, 119, 0.2);
    border: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:focus {
        border: none;
        outline: none;
    }

    &::backdrop {
        background: rgba(224, 228, 232, 0.9);
        animation: 0.4s ease fade;
    }

    &[open] {
        animation: 0.2s linear slidein;
    }

    @keyframes slidein {
        from {
            transform: translate3d(0, 5px, 0) translate(-50%, -50%);
        }
        to {
            transfrom: translate3d(0, 0, 0) translate(-50%, -50%);
        }
    }

    @keyframes fade {
        from {
            opacity: 0.3;
        }
        to {
            opacity: 1;
        }
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
        width: auto !important;
        height: auto !important;
        min-height: auto;
    }
`;

const Content = styled.div`
    text-align: center;
    padding: 0px 10px;
    img {
        width: 158px;
        margin: 0px 0px 30px;
    }
    Label {
        margin-bottom: 30px;
        line-height: 24px !important;
    }
`;

const IconContainer = styled.div`
    @media only screen and (max-width: 980px) {
        img {
            width: 100px;
            height: 100px;
        }
    }
`;

export const ThemedDialog: React.FC<IProps> = observer((props: IProps) => {
    const { isOpen, children, title } = props;
    const ref = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (isOpen) {
            ref.current?.showModal();
            document.body.classList.add("modal-open"); // prevent bg scroll
        } else {
            ref.current?.close();
            document.body.classList.remove("modal-open");
        }
    }, [isOpen]);

    //region properties

    const closeOnClickOutside = props.closeOnClickOutside ?? true;
    const showCloseIconButton = props.showCloseIconButton ?? true;
    const showTitle = !isEmptyOrWhitespace(title) && !isNullOrUndefined(title);
    const icon = props.icon ?? DefaultIcon;
    const showIcon = !props.hideIcon ?? true;
    //endregion properties

    const isClickInsideRectangle = (e: any, element: HTMLElement) => {
        const r = element.getBoundingClientRect();

        return e.clientX > r.left && e.clientX < r.right && e.clientY > r.top && e.clientY < r.bottom;
    };

    const handleOnClose = (e: any) => {
        if (closeOnClickOutside) {
            ref.current && !isClickInsideRectangle(e, ref.current) && onClose();
        }
    };

    const onClose = () => {
        if (props.onCloseCommand.canExecute()) {
            props.onCloseCommand.execute();
        }
    };

    // const render;
    return (
        <>
            {isOpen && (
                <StyledDialog ref={ref} onCancel={onClose} onClick={(e) => handleOnClose(e)}>
                    <Header>
                        {showCloseIconButton && <TransparentButton style={{ height: "30px", width: "30px" }} displayName={<CloseIcon />} command={props.onCloseCommand} />}
                    </Header>
                    <Content>
                        {showIcon && <IconContainer>{icon && <img src={icon} alt="" />}</IconContainer>}
                        {showTitle && <Label fontStyle="h2">{title}</Label>}
                        {children}
                    </Content>
                </StyledDialog>
            )}
        </>
    );
});
