import { PropsWithChildren, useState } from "react";

import { theme } from "Styles/AppTheme";
import { HeaderBase, IHeaderProps, IHeaderStyles } from "./HeaderBase";
import { pxToRem } from "../../Application";
import logo from "Assets/LifeShedLogo.svg";
import { HeaderViewModel } from "./HeaderViewModel";

export const Header = (props: PropsWithChildren<IHeaderProps>) => {
    const [viewModel] = useState(() => new HeaderViewModel());

    const getStyles = (): IHeaderStyles | undefined => {
        return {
            root: {
                CSSProperties: {
                    position: "relative",
                    backgroundColor: "white",
                    boxShadow: `3px 0px 6px #00000029`,
                    display: "flex",
                    justifyContent: "center",
                    minHeight: pxToRem(50),
                    zIndex: 1000,
                },
            },
            content: {
                CSSProperties: {
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    width: viewModel.isMobile && "60%",
                },
            },
            options: {
                CSSProperties: {
                    display: "flex",
                },
            },
            option: {
                CSSProperties: {
                    color: theme.palette.primary.text,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: viewModel.isMobile ? "15px 18px" : "15px 35px",
                },
            },
            optionActive: {
                CSSProperties: {
                    color: theme.palette.primary.text,
                    backgroundColor: theme.palette.primary.main,
                },
            },
            logoOption: {
                CSSProperties: {
                    backgroundColor: theme.palette.primary.main,
                    backgroundImage: `url(${logo})`,
                    backgroundPosition: "left center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    content: "",
                    height: pxToRem(65),
                    width: pxToRem(65),
                    position: "absolute",
                    left: pxToRem(20),
                    top: "12px",
                },
            },
        } as IHeaderStyles;
    };

    return <HeaderBase {...props} styles={getStyles()} />;
};
