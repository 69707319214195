import styled from "@emotion/styled";
import Tippy from "@tippyjs/react";
import { Button, IButtonProps } from "Components";

import * as Popper from "@popperjs/core";

type Placement = Popper.Placement;
const CustomButton = styled(Button)`
    //remove defaults
    border: none;
    border-radius: 0px !important;
    padding: 6px;
    margin: 0;
    font-size: 1rem;
    height: 30px;
    width: 30px;

    font-size: 18px;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid white;
`;

interface IStyledButtonProps extends IButtonProps {
    tooltipText?: string;
    toolTipPlacement?: Placement;
}

export const StyledButton: React.FC<IStyledButtonProps> = (props) => {
    const hasToolTip = !!props.tooltipText;

    if (hasToolTip) {
        return (
            <Tippy content={props.tooltipText} placement={props.toolTipPlacement}>
                <CustomButton {...props} />
            </Tippy>
        );
    }

    return <CustomButton {...props} />;
};
