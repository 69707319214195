import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { StudentSessionSubTargetModel, StudentSessionSubTargetModelValidator } from "./StudentSessionSubTargetModel";

export class StudentSessionSubTargetViewModel extends ViewModelBase<StudentSessionSubTargetModel> {
    public apiClient = new APIClient();
    //region UI observables
    public _showSessionPlanError: boolean = false;
    public _showDetailError: boolean = false;
    public _showMentoringError: boolean = false;
    public _showScoreError: boolean = false;
    public _isDisabled: boolean = true;
    //parent commands
    public onUpdateFieldCommandCallback?: ICommand;

    constructor(model: StudentSessionSubTargetModel = new StudentSessionSubTargetModel(), onUpdateFieldCommandCallback?: ICommand) {
        super(model);
        this.setValidator(new StudentSessionSubTargetModelValidator());
        makeObservable(this, {
            //observables
            _showSessionPlanError: observable,
            _showDetailError: observable,
            _showMentoringError: observable,
            _showScoreError: observable,
            _isDisabled: observable,
            //computed
            showSessionPlanError: computed,
            showDetailError: computed,
            showMentoringError: computed,
            showScoreError: computed,
            isDisabled: computed,
            //actions
            setShowSessionPlanError: action,
            setShowDetailError: action,
            setShowMentoringError: action,
            setShowScoreError: action,
            setIsDisabled: action,
            validateModel: action,
        });
        this.onUpdateFieldCommandCallback = onUpdateFieldCommandCallback;
    }

    //region properties

    public get showSessionPlanError(): boolean {
        return this._showSessionPlanError;
    }
    public get showDetailError(): boolean {
        return this._showDetailError;
    }
    public get showMentoringError(): boolean {
        return this._showMentoringError;
    }
    public get showScoreError(): boolean {
        return this._showScoreError;
    }
    public get score(): string {
        return this.model.score ? this.model.score.toString() : "";
    }

    public get isDisabled(): boolean {
        return this._isDisabled;
    }

    //region commands

    // NOTE: Validation is applied differently due to form requirements.
    // It occurs when:
    // 1. Clicking the Create button in the SessionReportView.
    // 2. Marking a SubTarget as complete.
    // 3. Updating a field in the SubTargetSessionReportView.
    public updateSessionPlanCommand = new RelayCommand(
        (value: string) => {
            this.updateField("sessionPlan", value);
            const isSessionPlanValid: string | undefined = this.getError("sessionPlan");
            this.setShowSessionPlanError(isSessionPlanValid != undefined);
            this.onUpdateFieldCommandCallback?.execute();
        },
        () => !this.isDisabled,
    );

    public updateDetailCommand = new RelayCommand(
        (value: string) => {
            this.updateField("detail", value);
            const isDetailValid: string | undefined = this.getError("detail");
            this.setShowDetailError(isDetailValid != undefined);
            this.onUpdateFieldCommandCallback?.execute();
        },
        () => !this.isDisabled,
    );

    public updateMentoringCommand = new RelayCommand(
        (value: string) => {
            this.updateField("mentoring", value);
            const isMentoringValid: string | undefined = this.getError("mentoring");
            this.setShowMentoringError(isMentoringValid != undefined);
            this.onUpdateFieldCommandCallback?.execute();
        },
        () => !this.isDisabled,
    );

    public updateScoreCommand = new RelayCommand(
        (value: string) => {
            //remove non-numeric characters
            const regex = /[^0-9]/g;
            let sanitizedValue = value.toString().replace(regex, "");
            sanitizedValue = value.length > 3 ? "10" : value.toString();

            //remove leading zeros
            sanitizedValue = sanitizedValue.replace(/^0+/, "");
            const sanitizedValueNumber = Number.parseInt(sanitizedValue);

            if (sanitizedValueNumber > 10) {
                this.updateField("score", 10);
            } else if (sanitizedValueNumber < 0) {
                this.updateField("score", 0);
            } else {
                this.updateField("score", sanitizedValueNumber);
            }
            const isScoreValid: boolean = this.getError("score") != undefined;
            this.setShowScoreError(isScoreValid);
            this.onUpdateFieldCommandCallback?.execute();
        },
        () => !this.isDisabled,
    );

    //region helpers
    private updateField = (fieldName: keyof FieldType<StudentSessionSubTargetModel>, value: any) => {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    };

    //region actions

    public setShowSessionPlanError = (value: boolean) => {
        this._showSessionPlanError = value;
    };
    public setShowDetailError = (value: boolean) => {
        this._showDetailError = value;
    };
    public setShowMentoringError = (value: boolean) => {
        this._showMentoringError = value;
    };
    public setShowScoreError = (value: boolean) => {
        this._showScoreError = value;
    };

    public setIsDisabled = (value: boolean) => {
        this._isDisabled = value;
    };

    public validateModel = () => {
        //validate
        this.isFieldValid("sessionPlan");
        this.isFieldValid("detail");
        this.isFieldValid("mentoring");
        this.isFieldValid("score");
        //get errors
        const isSessionPlanValid: string | undefined = this.getError("sessionPlan");
        const isDetailValid: string | undefined = this.getError("detail");
        const isMentoringValid: string | undefined = this.getError("mentoring");
        const isScoreValid: string | undefined = this.getError("score");
        ///set ui errors
        this.setShowSessionPlanError(isSessionPlanValid != undefined);
        this.setShowDetailError(isDetailValid != undefined);
        this.setShowMentoringError(isMentoringValid != undefined);
        this.setShowScoreError(isScoreValid != undefined);
    };
}
