import styled from "@emotion/styled";
import { theme } from "Styles";

export const Container = styled.div<{ backgroundColour?: string }>`
    background-color: ${({ backgroundColour }) => backgroundColour ?? theme.palette.common.white};
    display: grid;
    grid-template-columns: 35px 1fr 90px;
    &.isMobile {
        grid-template-columns: 25px 1fr;
    }
`;

export const ButtonGroup = styled.div`
    display: grid;
    grid-template-columns: 30px 30px;
    column-gap: 10px;
    align-items: start;

    border-left: 1px solid ${theme.palette.tableHeader.light}; // this should be themed.
    padding: 10px;

    &.isMobile {
        border-left: none;
        display: flex;
        flex-direction: column-reverse;
        padding: 10px 0px;

        & :last-child {
            margin-bottom: 5px;
        }
    }

    label {
        gap: 0px !important;
    }
    input {
        height: 30px !important;
        width: 30px !important;
    }
`;

export const Reference = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 10px;
    border-right: 1px solid ${theme.palette.tableHeader.main};
`;

export const ReferenceInnerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20px 20px;
    justify-content: center;

    label {
        text-align: center;
    }
`;

export const TargetDescription = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    padding: 10px;
    border-bottom: 1px dashed transparent;

    &.show {
        border-bottom: 1px dashed ${theme.palette.tableHeader.main};
        &.isMobile {
            border-bottom: none;
        }
    }
`;

export const Main = styled.div`
    &.isMobile {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        border-bottom: 1px dashed ${theme.palette.tableHeader.main};
    }
`;

export const SessionReportContainer = styled.div<{ backgroundColour?: string }>`
    background-color: ${({ backgroundColour }) => backgroundColour ?? theme.palette.common.white};
    display: block;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    opacity: 0;

    &.show {
        max-height: 2000px;
        opacity: 1;
        overflow: visible;
    }

    &.isMobile {
        position: relative;
        margin-left: 24px;
        border-left: 1px solid ${theme.palette.tableHeader.main};
        z-index: 1;

        &::after {
            position: absolute;
            height: 100%;
            width: 24px;
            content: "";
            top: 0;
            left: -25px;
            background-color: ${({ backgroundColour }) => backgroundColour ?? theme.palette.common.white};
            z-index: -1;
        }
    }
`;
