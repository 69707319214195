import React from "react";
import { observer } from "mobx-react-lite";
import { Label, theme } from "Application";
import { StudentTargetViewModel } from "./StudentTargetViewModel";
import { Container, Reference, Target, ButtonGroup, Targets, TargetDescription, Main, ReferenceInnerContainer } from "./StudentTargetView.styles";

import { StudentSubTargetView } from "../StudentSubTarget/StudentSubTargetView";
import classNames from "classnames";
import { ShowHideButton } from "Views/Students/Forms/StudentTargets/Shared/Components/ShowHideButton";
import { ExclamationIconSVG } from "Assets/Icons/ExclamationIconSVG";
import { StudentSessionLegacyFormView } from "../StudentSessionLegacyForm/StudentSessionLegacyFormView";

interface IStudentTargetViewProps {
    viewModel: StudentTargetViewModel;
}

export const StudentTargetView: React.FC<IStudentTargetViewProps> = observer(({ viewModel }) => {
    const { isMobile } = viewModel;

    return (
        <div>
            <Container className={classNames({ show: viewModel.showSubTargets, isMobile: isMobile })}>
                <Reference>
                    <ReferenceInnerContainer>
                        <Label fontStyle="h4">{viewModel.reference}</Label>
                        {viewModel.showError && <ExclamationIconSVG backgroundcolor={theme.palette.common.error} color={theme.palette.common.white} />}
                    </ReferenceInnerContainer>
                </Reference>
                <Main>
                    <Target className={classNames({ show: viewModel.showSubTargets, isMobile: isMobile })}>
                        <TargetDescription>
                            <Label fontStyle={"field"}>{viewModel.description}</Label>
                        </TargetDescription>
                        <ButtonGroup>
                            <ShowHideButton command={viewModel.showHideSubTargetsCommand} show={viewModel.showSubTargets} />
                        </ButtonGroup>
                    </Target>
                    {!isMobile && (
                        <>
                            <Targets className={classNames({ show: viewModel.showSubTargets })}>
                                {viewModel.subTargetViewModels.map((subTarget, index) => (
                                    <StudentSubTargetView viewModel={subTarget} key={subTarget.model.KEY} index={index} />
                                ))}
                            </Targets>
                        </>
                    )}
                </Main>
            </Container>
            {isMobile && (
                <>
                    <Targets className={classNames({ show: viewModel.showSubTargets, isMobile: isMobile })}>
                        {viewModel.subTargetViewModels.map((subTarget, index) => (
                            <StudentSubTargetView viewModel={subTarget} key={subTarget.model.KEY} index={index} />
                        ))}
                    </Targets>
                </>
            )}
        </div>
    );
});
