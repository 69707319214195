//#region Imports & External Dependencies
import { FieldType, KeyValuePair, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { makeObservable, observable, computed, action } from "mobx";
import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { LookupStore } from "Stores/Domain/LookupStore";
import moment from "moment";
import { SessionDocument, WeeklyRotaSessionReportModel, WeeklyRotaSessionReportModelValidator } from "./WeeklyRotaSessionReportModel";
import { FilesViewModel } from "Views/Students/Forms/StudentReports/Files/FilesViewModel";
import { StudentAbsenceViewModel } from "Views/Students/Forms/StudentReports/StudentAbsence/StudentAbsenceViewModel";
import { GETWeeklyRotaSessionReportEndpoint } from "../Endpoints/GETWeeklyRotaSessionReportEndpoint";
import { POSTSaveWeeklyRotaSessionDocumentsEndpoint } from "../Endpoints/POSTSaveWeeklyRotaSessionDocumentsEndpoint";
import { POSTSaveWeeklyRotaSessionEndpoint } from "../Endpoints/POSTSaveWeeklyRotaSessionEndpoint";
import { GETWeeklyRotaSessionReportByIdEndpoint } from "../Endpoints/GETWeeklyRotaSessionReportByIdEndpoint";
import { AccountStore } from "Stores/Domain/AccountStores";
import { POSTSaveActivityOptionsEndpoint } from "../Endpoints/POSTSaveActivityOptionsEndpoint";
import axios, * as Axios from "axios";
import { StudentTargetViewModel } from "Views/Shared/Views/StudentTarget/StudentTargetViewModel";
import { StudentSubTargetViewModel } from "Views/Shared/Views/StudentSubTarget/StudentSubTargetViewModel";
import { StudentSessionSubTargetViewModel } from "Views/Shared/Views/StudentSessionSubTarget/StudentSessionSubTargetViewModel";
import { StudentSessionSubTargetModel } from "Views/Shared/Views/StudentSessionSubTarget/StudentSessionSubTargetModel";
import { StudentSessionLegacyFormViewModel } from "Views/Shared/Views/StudentSessionLegacyForm/StudentSessionLegacyFormViewModel";

//#endregion

//#region Class/Component Definition
export class WeeklyRotaSessionReportViewModel extends ViewModelBase<WeeklyRotaSessionReportModel> {
    //#region Properties & State
    public apiClient = new APIClient();
    public accountStore = container.resolve(AccountStore);
    public lookupStore = container.resolve(LookupStore);
    public filesViewModel = new FilesViewModel();
    public studentAbsenceViewModel: StudentAbsenceViewModel;
    public _showSessionDocPhoto: boolean = false;
    public isEditForm: boolean = false;
    public studentId: string = "";
    public showError: boolean = false;
    public _studentTargetViewModels = observable<StudentTargetViewModel>([]);
    public studentSessionLegacyFormViewModel?: StudentSessionLegacyFormViewModel;
    public _formErrorMessage: string | null = null;
    //#endregion

    //#region Constructor/Initialization
    constructor(id: string | undefined, mentorId: string | undefined, reportId: string | undefined, studentId: string | undefined) {
        super(new WeeklyRotaSessionReportModel());
        this.model.id = id!;
        this.model.mentorId = mentorId!;
        this.model.filesViewModel = this.filesViewModel;
        this.model.studentId = studentId!;
        this.setValidator(new WeeklyRotaSessionReportModelValidator());
        this.studentAbsenceViewModel = new StudentAbsenceViewModel(this.model.studentAbsence);

        makeObservable(this, {
            //observables
            _showSessionDocPhoto: observable,
            filesViewModel: observable,
            isEditForm: observable,
            studentId: observable,
            showError: observable,
            _formErrorMessage: observable,
            _studentTargetViewModels: observable,
            //actions
            validateStudentTargetViewModels: action,
            initializeViewModels: action,
            setFormErrorMessage: action,
            canSubmitForm: action,
            setShowSessionDocPhoto: action,
            //computed
            canSubmitSessionDoc: computed,
            displayInvolvement: computed,
            displayName: computed,
            displayStatus: computed,
            displayWellbeing: computed,
            getActivity: computed,
            getAttendance: computed,
            getDocumentCategory: computed,
            getReason: computed,
            getSessionStatus: computed,
            getsessionMentorName: computed,
            renderForm: computed,
            renderGetAttendance: computed,
            showExport: computed,
            showUploadButton: computed,
            mentorKeyValuePairs: computed,
            mentorWithoutMatchingEmail: computed,
            studentTargetViewModels: computed,
            showSessionDocPhoto: computed,
        });
    }
    //#endregion

    //#region actions
    public setFormErrorMessage(value: string | null) {
        this._formErrorMessage = value;
    }

    public setShowSessionDocPhoto = (value: boolean) => {
        this._showSessionDocPhoto = value;
    };

    public get showSessionDocPhoto() {
        return this._showSessionDocPhoto;
    }

    public initializeViewModels = () => {
        this._studentTargetViewModels.replace(
            this.model.studentTargets.map((st) => {
                const vm = new StudentTargetViewModel(st);
                vm._subTargetViewModels.replace(
                    st.subTargets.map((sst) => {
                        const sstVM = new StudentSubTargetViewModel(sst, vm.onSubTargetCompletedByMentorCommandCallback);
                        sstVM._studentSessionSubTargetViewModel = new StudentSessionSubTargetViewModel(
                            sst.studentSessionSubTargetModel!,
                            sstVM.onUpdateSessionReportCallbackCommand,
                        );
                        if (sstVM.hasExistingSession) {
                            sstVM.setValue("isCompletedByMentor", true);
                            sstVM._studentSessionSubTargetViewModel.setIsDisabled(false);
                        }
                        return sstVM;
                    }),
                );
                return vm;
            }),
        );
    };
    //#endregion

    //#region Public Methods & Commands
    public initialize = async (studentId?: string, reportId?: string) => {
        if (studentId) {
            await this.apiClient.sendAsync(new GETWeeklyRotaSessionReportEndpoint(studentId, this));
            this.initializeViewModels();
            return;
        }
        if (reportId) {
            await this.apiClient.sendAsync(new GETWeeklyRotaSessionReportByIdEndpoint(reportId, this));
            this.initializeViewModels();
            return;
        }
    };

    public showEditFormCommand = new RelayCommand(() => {
        this.isEditForm = !this.isEditForm;
    });

    public navigateToBackCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Admin.Mentor.WeeklyRota);
    });

    public updateSessionDateCommand = new RelayCommand((value: Date) => {
        this.updateField("sessionDate", value);
    });

    public updateSessionStartTimeCommand = new RelayCommand((value: string) => {
        this.updateField("sessionStartTime", moment(new Date(value)));
    });

    public updateSessionEndTimeCommand = new RelayCommand((value: string) => {
        this.updateField("sessionEndTime", moment(new Date(value)));
    });

    public updateSessionStatusCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("attendance", kvp.key);
        if (kvp.key === "0") {
            this.updateField("activityTypeId", null);
            this.updateField("sessionPlan", "");
            this.updateField("subTargets", "");
            this.updateField("mentoring", "");
            this.updateField("wellbeingId", null);
            this.updateField("involvementId", null);
            this.studentAbsenceViewModel.updateIsAttendedCommand.execute(false);
        } else {
            this.studentAbsenceViewModel.reUpdateAbsenseTypeIdCommand.execute(null);
            this.studentAbsenceViewModel.updateReasonCommand.execute("");
            this.studentAbsenceViewModel.updateIsAttendedCommand.execute(true);
        }
    });

    public updateActivityTypeCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("activityTypeId", kvp.key);
        this.updateField("activityOptionName", kvp || null);

        if (!kvp.key) {
            let _ = this.apiClient.sendAsync(new POSTSaveActivityOptionsEndpoint(this), this.model);
        }
    });

    public updateDocumentCategoryCommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("documentCategoryId", kvp.key);
    });

    public updateReportIncidentCommand = new RelayCommand((value: boolean) => {
        this.updateField("reportIncident", value);
    });

    public updateSessionPlanCommand = new RelayCommand((value: string) => {
        this.updateField("sessionPlan", value);
    });

    public updateSubTargetsCommand = new RelayCommand((value: string) => {
        this.updateField("subTargets", value);
    });

    public updateMentoringCommand = new RelayCommand((value: string) => {
        this.updateField("mentoring", value);
    });

    public updateMentorCommand = new RelayCommand((value: KeyValuePair) => {
        this.updateField("mentorId", value.key);
    });

    public updateWellbeingCommand = new RelayCommand((value: string) => {
        this.setValue("wellbeingId", value);
    });

    public updateInvolvementCommand = new RelayCommand((value: string) => {
        this.setValue("involvementId", value);
    });

    public checkSelectStudentTargetCommand = new RelayCommand((id: string) => {
        if (id) {
            const index = this.model.studentTargetIds.indexOf(id);
            if (index === -1) {
                this.model.studentTargetIds.push(id);
            } else {
                this.model.studentTargetIds.splice(index, 1);
            }
        }
    });

    public getSessionStatusById = (id: string) => {
        if (this.getSessionStatus.length > 0) {
            const sessionStatus = this.getSessionStatus.find((m) => m.key === id);
            return sessionStatus ? sessionStatus.text : "";
        }
        return "";
    };

    public getSessionActivityType = (id: string) => {
        if (this.getActivity.length > 0) {
            const getActivity = this.getActivity.find((m) => m.key === id);
            return getActivity ? getActivity.text : "";
        }
        return "";
    };

    public getReasonType = (id: string) => {
        if (this.getReason.length > 0) {
            const getReason = this.getReason.find((m) => m.key === id);
            return getReason ? getReason.text : "";
        }
        return "";
    };

    public isDisabled = (): boolean => {
        return !this.saveStudentSessionCommand.canExecute();
    };

    public getDocumentCategoryName = (id: string) => {
        const studentAttendance = this.getDocumentCategory.find((m) => m.key === id);
        return studentAttendance?.text;
    };

    public showSessionDocPhotoCommand = new RelayCommand(() => {
        this.toggleShowDocPhoto();
    });

    public toggleShowDocPhoto = () => {
        this._showSessionDocPhoto = !this._showSessionDocPhoto;
        if (!this._showSessionDocPhoto) {
            this.filesViewModel.model.files.clear();
        }
    };

    public cancelCommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.Students.Student.StudentReports.Edit.replace(":id", this.model.id!));
    });

    public showErrorsCommand = new RelayCommand(() => {
        this.showError = !this.showError;
    });

    public saveStudentSessionCommand = new RelayCommand(async () => {
        const canSubmitForm = this.canSubmitForm();

        if (canSubmitForm) {
            let _ = await this.apiClient.sendAsync(new POSTSaveWeeklyRotaSessionEndpoint(this), this.model);
            if (this.apiClient.IsRequestSuccessful) {
                this.history.push(AppUrls.Client.Admin.Mentor.WeeklyRota);
                this.isEditForm = false;
            } else {
                const error = this.apiClient.ValidationMessage;
                this.setFormErrorMessage(error);
            }
        }
    });

    public uploadStudentReportCommand: ICommand = new RelayCommand(async () => {
        if (this.canSubmitSessionDoc && this.filesViewModel.model.files.length > 0) {
            let _ = await this.apiClient.sendAsync(new POSTSaveWeeklyRotaSessionDocumentsEndpoint(this), this.model);
            if (this.apiClient.IsRequestSuccessful) {
                this._showSessionDocPhoto = false;
                this.filesViewModel = new FilesViewModel();
            }
        }
    });

    public removeStudentDocumentsCommand: ICommand = new RelayCommand((studentDocument: SessionDocument) => {
        const studentDocumentToRemove = this.model.sessionDocument.find((m) => m.KEY === studentDocument.KEY);
        if (studentDocumentToRemove) {
            this.model.sessionDocument.remove(studentDocumentToRemove);
        }
    });

    public downloadResourceDocumentCommand: ICommand = new RelayCommand(async (id: string, fileName?: string) => {
        try {
            const config: Axios.AxiosRequestConfig = {};
            config.responseType = "blob";
            config.headers = { Authorization: `Bearer ${sessionStorage.getItem(".auth")}` };
            await axios
                .get(AppUrls.Server.Student.StudentSessions.GetStudentDocumentForDownloadById.replace(":id", id), config)
                .then((response: any) => {
                    const filename = fileName!;
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) {
            return { error };
        }
    });
    //#endregion

    //#region Private Methods & Helpers
    private async updateField(fieldName: keyof FieldType<WeeklyRotaSessionReportModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
    //#endregion

    //#region Computed Properties & Getters

    public get formErrorMessage() {
        return this._formErrorMessage;
    }
    public get showFormErrorWarning() {
        return this._formErrorMessage != null;
    }
    public get canSubmitSessionDoc(): boolean {
        const isFormValid = this.isFieldValid("documentCategoryId");
        return isFormValid ? true : false; //todo:  this is a hack to get around the fact that the file upload is not working
    }

    public get displayInvolvement() {
        const displayItem = this.model.wellbeing.find((item) => item.id === this.model.involvementId);
        return displayItem;
    }

    public get displayName() {
        return this.model.sessionStatusId ? "UPDATE" : "CREATE";
    }

    public get displayStatus() {
        const studentAttendance = this.getAttendance.find((m) => m.key === this.model.attendance);
        return studentAttendance?.text === "Attended" ? "#27E6A3" : studentAttendance?.text === "Absent" ? "#E6054E" : "";
    }

    public get displayWellbeing() {
        const displayItem = this.model.wellbeing.find((item) => item.id === this.model.wellbeingId);
        return displayItem;
    }

    public get getActivity() {
        return this.lookupStore.activityTypeKeyValuePairs;
    }

    public get getAttendance() {
        return [
            { key: "0", text: "Absent" },
            { key: "1", text: "Attended" },
        ] as KeyValuePair[];
    }

    public get getDocumentCategory() {
        return this.lookupStore.documentCategoryKeyValuePairs;
    }

    public get getReason() {
        return this.lookupStore.absenceTypeKeyValuePairs;
    }

    public get getSessionStatus() {
        return this.lookupStore.sessionStatusKeyValuePairs;
    }

    public get getsessionMentorName() {
        const mentorName = this.mentorKeyValuePairs.find((m) => m.key === this.model.mentorId);
        return mentorName?.text;
    }

    public get mentorWithoutMatchingEmail(): boolean {
        return this.model.sessionMentor.email !== this.accountStore.DisplayName;
    }

    public get renderForm() {
        const studentAttendance = this.getAttendance.find((m) => m.key === this.model.attendance);
        return studentAttendance?.text !== "Absent";
    }

    public get renderGetAttendance(): KeyValuePair[] {
        const studentAttendance = this.getAttendance.filter((m) => m.key === this.model.attendance);
        const attended = this.getAttendance.filter((m) => m.key === "1");
        return studentAttendance.length > 0 ? studentAttendance : attended;
    }

    public get showExport() {
        return this.model.id != null;
    }

    public get showUploadButton(): boolean {
        this.model.filesViewModel = this.filesViewModel;
        return false;
    }

    public get mentorKeyValuePairs(): KeyValuePair[] {
        return this.model.mentor.map((model: any) => {
            return { key: model.id, text: model.firstName + " " + model.lastName } as KeyValuePair;
        });
    }

    public get showInvolvementError() {
        return this.getError("involvementId") != undefined;
    }

    public get showWellbeingError() {
        return this.getError("wellbeingId") != undefined;
    }

    public get studentTargetViewModels() {
        return this._studentTargetViewModels;
    }

    public canSubmitForm(): boolean {
        const isFormValid = this.isModelValid();
        if (!isFormValid) {
            const errors: KeyValuePair[] = this.getErrors();
            const message = errors[0].text;
            this.setFormErrorMessage(message);
            return false;
        }

        const isStudentAbsenceValid = this.studentAbsenceViewModel.isModelValid();
        if (!isStudentAbsenceValid) {
            const errors: KeyValuePair[] = this.getErrors();
            const message = errors[0].text;
            this.setFormErrorMessage(message);
            return false;
        }

        const areStudentTargetsValid = this.validateStudentTargetViewModels();
        if (!areStudentTargetsValid) {
            this.setFormErrorMessage("Please fix the errors in the student targets");
            return false;
        }

        return isFormValid && isStudentAbsenceValid && areStudentTargetsValid;
    }

    //#endregion
    //#region Validation Methods
    public validateStudentTargetViewModels() {
        let isValid = true;
        const stsCompletedByMentor = this.studentTargetViewModels.slice().filter((st) => st.subTargetViewModels.some((sst) => sst.model.isCompletedByMentor));
        stsCompletedByMentor.forEach((st) => {
            isValid = st.validateModel();
        });
        return isValid;
    }

    //#endregion

    //#region command
    public dismissFormHasErrorsWarningCommand = new RelayCommand(() => {
        this.setFormErrorMessage(null);
    });
}
