import React from "react";
import { Label } from "Application";
import styled from "@emotion/styled";

const Container = styled.div`
    height: 100px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
`;

export const Header: React.FC<{ title: string }> = ({ title }) => {
    return (
        <Container>
            <Label fontStyle="h1">{title}</Label>
        </Container>
    );
};
