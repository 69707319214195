import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { KeyValuePair } from "@shoothill/core";

export class GetFilteredStudentKeyValuePairsRequest {
    public keyword: string = "";
    public precedingStudentId: string | null = null;
}

export class GetFilteredStudentKeyValuePairsResponse {
    public keyValuePairs: KeyValuePair[] = [];
    public isEndOfList: boolean = false;
}

export class POST_GetFilteredStudentKeyValuePairsEndpoint extends Endpoint<GetFilteredStudentKeyValuePairsRequest, GetFilteredStudentKeyValuePairsResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Student.GetFilteredStudentKeyValuePairs);
    }

    public async HandleRequestAsync(request: GetFilteredStudentKeyValuePairsRequest): Promise<GetFilteredStudentKeyValuePairsRequest> {
        return request;
    }

    public async HandleResponseAsync(response: GetFilteredStudentKeyValuePairsResponse): Promise<GetFilteredStudentKeyValuePairsResponse> {
        return response;
    }
}
