import React from "react";
import { PrivateRoute } from "Components/PrivateRoute";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";
import { Routes } from "react-router-dom";
import { Route } from "react-router";
import { ReportsView } from "./Views/Reports/ReportsView";
import { AppUrls } from "AppUrls";

const ReportRoutes: React.FC = () => {
    const accountStore = container.resolve(AccountStore);
    const isAdmin = accountStore.isAdmin;
    return (
        <Routes>
            <Route element={<PrivateRoute isAllowed={() => accountStore.IsLoggedIn && isAdmin} />}>
                <Route path={"*"} element={<ReportsView />} />
            </Route>
        </Routes>
    );
};

export default ReportRoutes;
