import React from "react";
import { ReportsViewModel } from "../Views/Reports/ReportsViewModel";
import { Button, EditDate } from "Components";
import { Container, Row } from "./Form.styles";
import { ThemedEditSelect } from "Styles/ThemedPrimitives/Style1/ThemeEditSelect";
import { observer } from "mobx-react-lite";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const loadingAnimation = keyframes`
0% {content: "Exporting Report";} 
33% {content: "Exporting Report.";}
66% {content: "Exporting Report.."; }
100% {content: "Exporting Report...";}
`;
const ExportButton = styled(Button)`
    position: relative;

    &:after {
        display: inline-block;
        animation: ${(props) => (props.isExporting ? loadingAnimation : "")} 1s linear infinite;
        content: "${(props: { isExporting: boolean }) => (props.isExporting ? "" : "Export Report")}";

        position: absolute;
        top: 50%;
        left: ${(props) => (props.isExporting ? "20px" : "50%")};
        transform: ${(props) => (props.isExporting ? "translateY(-50%)" : "translate(-50%, -50%)")};
    }
`;
export const Form: React.FC<{ viewModel: ReportsViewModel }> = observer(({ viewModel }) => {
    return (
        <Container>
            <Row>
                <ThemedEditSelect
                    displayName="Choose a Student"
                    options={viewModel.studentOptions}
                    command={viewModel.updateFilterStudentIdCommand}
                    value={() => viewModel.getValue("filterStudentId")}
                    isLoading={viewModel.isLoading}
                    onScrollEnd={viewModel.getNextStudentKeyValuePairs}
                    onSearchInputChange={viewModel.getStudentKeyValuePairsByKeyword}
                    validationMessage={() => viewModel.getError("filterStudentId")}
                    canClear
                    onClear={viewModel.getFistStudentKeyValuePairs}
                />
                <EditDate
                    displayName={"Date from"}
                    value={() => viewModel.getValue("filterStartDate")}
                    command={viewModel.updateFilterStartDateCommand}
                    isWeekDisable
                    validationMessage={() => viewModel.getError("filterStartDate")}
                />
                <EditDate
                    displayName={"Date to"}
                    value={() => viewModel.getValue("filterEndDate")}
                    command={viewModel.updateFilterEndDateCommand}
                    isWeekDisable
                    validationMessage={() => viewModel.getError("filterEndDate")}
                />
                <ExportButton command={viewModel.exportReportCommand} type="button" displayName={""} paletteColor="primary" isExporting={viewModel.isExporting} />
            </Row>
        </Container>
    );
});
