import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { CSSProperties, PropsWithChildren } from "react";
import { themeColourOptions, themeFontStyleOptions, theme, getThemeColorOption, getThemeFontStyleOption, FontVariant } from "Application";
import styled from "@emotion/styled";
import { space, SpaceProps } from "styled-system";

const InternalLabel = styled.label<SpaceProps>`
    {...props}
    ${space}; 
`;
interface Props {
    color: string;
    fontStyle: FontVariant;
    pointer?: boolean;
}

const StyledLabel = styled(InternalLabel)<Props>`
    /*  Theme properties*/
    color: ${(p) => p.color ?? theme.palette.field.text};
    font-family: ${(p) => p.fontStyle.fontFamily ?? theme.defaultFontStyle.fontFamily};
    font-size: ${(p) => p.fontStyle.fontSize ?? theme.defaultFontStyle.fontSize};
    // font-weight: ${(p) => p.fontStyle.fontWeight ?? theme.defaultFontStyle.fontWeight};
    letter-spacing: ${(p) => p.fontStyle.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
    // line-height: ${(p) => p.fontStyle.lineHeight ?? theme.defaultFontStyle.lineHeight};
    text-transform: ${(p) => p.fontStyle.textTransform ?? theme.defaultFontStyle.textTransform};
    cursor: ${(p) => (p.pointer ? "pointer" : "default")};
    /*End theme properties*/
    /* Resets */
    transition: 0.25s ease all;
    display: block;
    /* End Resets */
    line-height: 15px;
    font-weight: 400;
    // color:#171716;
    letter-spacing: -0.12px;
    &.statusText {
        font: normal normal bold 12px/16px Open Sans;
        letter-spacing: 0px;
        text-transform: uppercase;
    }
`;

/**
 * Text interface.
 */
type ILabelBaseProps = {
    className?: string;
    style?: CSSProperties | undefined;
    fontStyle?: themeFontStyleOptions;
    color?: themeColourOptions;
    htmlFor?: string;
    ref?: React.Ref<HTMLLabelElement>;
    pointer?: boolean;
} & SpaceProps;

export const Label: React.FC<PropsWithChildren<ILabelBaseProps>> = observer((props: PropsWithChildren<ILabelBaseProps>) => {
    // #region Code Behind
    const color = getThemeColorOption(props.color);
    const font = getThemeFontStyleOption(props.fontStyle);

    const getClasseNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const getStyles = (): CSSProperties | undefined => {
        return !isNullOrUndefined(props.style) ? props.style : undefined;
    };

    // #endregion Code Behind

    return (
        <StyledLabel
            ref={props.ref}
            m={props.m}
            p={props.p}
            mt={props.mt}
            mr={props.mr}
            mb={props.mb}
            ml={props.ml}
            pt={props.pt}
            pr={props.pr}
            pb={props.pb}
            pl={props.pl}
            color={color}
            fontStyle={font}
            pointer={props.pointer}
            style={getStyles()}
            className={getClasseNames()}
            htmlFor={props.htmlFor}
        >
            {props.children}
        </StyledLabel>
    );
});

Label.defaultProps = {};
