import { KeyValuePair, ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ReportsModel extends ModelBase<ReportsModel> {
    public filteredStudentKeyValuePairs = observable<KeyValuePair>([]);
    public isEndOfList: boolean = false;
    //#region filters
    public filterStudentKeyword: string = "";
    public filterStudentId: string | null = null;
    public filterStartDate: Date | null = null;
    public filterEndDate: Date | null = null;
    //#endregion filters
    constructor() {
        super();
        makeObservable(this, {
            isEndOfList: observable,
            //region filters
            filterStudentId: observable,
            filterStartDate: observable,
            filterEndDate: observable,
        });
    }
}

export class ReportsModelValidator extends Validator<ReportsModel> {
    constructor() {
        super();
        this.ruleFor("filterStudentId").notNull().withMessage("Please select a student");

        this.ruleFor("filterStartDate")
            .notNull()
            .withMessage("Please select a start date")
            .must((value, model) => (model.filterEndDate ? value! < model.filterEndDate : true))
            .withMessage("Start date must be after the end date");

        this.ruleFor("filterEndDate")
            .notNull()
            .withMessage("Please select a end date")
            .must((value, model) => (model.filterStartDate ? value! > model.filterStartDate : true))
            .withMessage("End date must be before the start date");
    }
}
