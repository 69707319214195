import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { FieldType, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, IKeyState, RelayCommand } from "Application";
import { ReportsModel, ReportsModelValidator } from "./ReportsModel";
import {
    GetFilteredStudentKeyValuePairsRequest,
    GetFilteredStudentKeyValuePairsResponse,
    POST_GetFilteredStudentKeyValuePairsEndpoint,
} from "Views/Reports/Endpoints/POST_GetFilteredStudentKeyValuePairsEndpoint";
import { ExportStudentProgressReportRequest, POST_ExportStudentProgressReportEndpoint } from "Views/Reports/Endpoints/POST_ExportStudentProgressReportEndpoint";
import moment from "moment";
import axios, * as Axios from "axios";
import { AppUrls } from "AppUrls";
export class ReportsViewModel extends ViewModelBase<ReportsModel> {
    public apiClient = new APIClient();

    //#region observables
    public _isLoading: boolean = false;
    public _isExporting: boolean = false;
    public loadTimeoutId: NodeJS.Timeout | null = null;
    //#endregion observables

    constructor() {
        super(new ReportsModel());
        this.setValidator(new ReportsModelValidator());
        makeObservable(this, {
            //observable
            _isLoading: observable,
            _isExporting: observable,
            //computed
            studentOptions: computed,
            isLoading: computed,
            isExporting: computed,
            //actions
            setIsLoading: action,
            setIsExporting: action,
            setLoadTimeoutId: action,
        });
    }

    //#region core
    public initialize = async () => {
        this.debouncedLoading(this.getFilteredStudentKeyValuePairs, 0);
    };

    public reset = () => {
        this.resetModel();
    };

    //#endregion core

    //#region MobX
    //#region actions
    public setIsLoading = (value: boolean) => {
        this._isLoading = value;
    };

    public setIsExporting = (value: boolean) => {
        this._isExporting = value;
    };

    public setLoadTimeoutId = (loadTimeoutId: NodeJS.Timeout) => {
        this.loadTimeoutId = loadTimeoutId;
    };

    //#endregion actions

    //#region computed values
    public get studentOptions(): KeyValuePair[] {
        return this.model.filteredStudentKeyValuePairs?.slice();
    }

    public get isLoading(): boolean {
        return this._isLoading;
    }

    public get isExporting(): boolean {
        return this._isExporting;
    }

    //#endregion computed values

    //#endregion MobX

    //#region api calls

    //#region student key value pair options

    private getFilteredStudentKeyValuePairs = async (replace: boolean = true) => {
        this.setIsLoading(true);
        const request: GetFilteredStudentKeyValuePairsRequest = new GetFilteredStudentKeyValuePairsRequest();
        request.keyword = this.model.filterStudentKeyword;
        if (!replace) {
            request.precedingStudentId = this.model.filteredStudentKeyValuePairs.slice()[this.model.filteredStudentKeyValuePairs.length - 1]?.key;
        }
        const endpoint = new POST_GetFilteredStudentKeyValuePairsEndpoint();
        await this.apiClient.sendAsync(endpoint, request);
        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                const response: GetFilteredStudentKeyValuePairsResponse = this.apiClient.Response();
                if (replace) {
                    this.model.filteredStudentKeyValuePairs.replace(
                        response.keyValuePairs.map((r) => {
                            return { key: r.key, text: r.text } as KeyValuePair;
                        }),
                    );
                } else {
                    const KVPs = response.keyValuePairs.map((r) => {
                        return { key: r.key, text: r.text } as KeyValuePair;
                    });
                    const retVal = [...this.model.filteredStudentKeyValuePairs.concat(KVPs)];
                    this.model.filteredStudentKeyValuePairs.replace(retVal);
                }
                this.model.isEndOfList = response.isEndOfList;
            });
            this.setIsLoading(false);
        }
    };

    public debouncedGetStudentKeyValuePairsAsync = (replace: boolean = true) => {
        this.debouncedLoading(() => this.getFilteredStudentKeyValuePairs(replace), 500);
    };

    public getStudentKeyValuePairsByKeyword = (keyword: string) => {
        const previousKeyword = this.model.filterStudentKeyword;

        const isKeywordUnchanged = previousKeyword === keyword;
        const isKeywordInvalid = keyword.length < 2 && keyword.length !== 0;

        if (isKeywordUnchanged || isKeywordInvalid) return;

        this.setValue("filterStudentKeyword", keyword);
        this.debouncedLoading(() => this.getFilteredStudentKeyValuePairs(), 500);
    };

    public getFistStudentKeyValuePairs = () => {
        this.setValue("filterStudentKeyword", null);
        this.setValue("filterStudentId", null);
        this.getFilteredStudentKeyValuePairs();
    };

    public getNextStudentKeyValuePairs = () => {
        if (this.model.isEndOfList) return;
        this.getFilteredStudentKeyValuePairs(false);
    };

    //#endregion student key value pair options

    //#region export report

    public exportStudentProgressReport = async () => {
        if (this.apiClient.IsBusy) return;
        this.setIsExporting(true);

        try {
            const request = {
                filterStudentId: this.model.filterStudentId,
                filterStartDate: moment(this.model.filterStartDate).startOf("day").toDate(),
                filterEndDate: moment(this.model.filterEndDate).endOf("day").toDate(),
            };

            const config: Axios.AxiosRequestConfig = {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(".auth")}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(AppUrls.Server.Reports.ExportStudentProgressReport, request, config);

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
            link.setAttribute("download", `StudentProgressReport_${moment().format("DDMMYYYY")}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e: any) {
            console.error("Export error:", e);
            // Add error handling here
            // if (e.response?.status === 401) {
            //     // Handle unauthorized error - perhaps redirect to login
            //     window.location.href = "/login";
            // }
        } finally {
            this.setIsExporting(false);
        }
    };

    //#endregion export report

    //#endregion

    //#region properties

    //#endregion

    //#region commands
    public updateFilterStudentKeywordCommand: ICommand = new RelayCommand((v: string) => {
        const previousSearchString = this.model.filterStudentKeyword;
        this.setValue("filterStudentKeyword", v);
        const isEmptySearch = this.model.filterStudentKeyword.length === 0;
        const isValidSearch = this.model.filterStudentKeyword.length > 1 && this.model.filterStudentKeyword !== previousSearchString;
        this.setValue("filterStudentKeyword", v);
        if (isEmptySearch || isValidSearch) {
            this.debouncedGetStudentKeyValuePairsAsync();
        }
    });
    public updateFilterStudentIdCommand: ICommand = new RelayCommand((kvp: KeyValuePair) => {
        this.updateField("filterStudentId", kvp.key);
    });
    public updateFilterStartDateCommand: ICommand = new RelayCommand((value: Date) => {
        this.updateField("filterStartDate", moment(new Date(value)));
    });
    public updateFilterEndDateCommand: ICommand = new RelayCommand((value: Date) => {
        this.updateField("filterEndDate", moment(new Date(value)));
    });
    public exportReportCommand: ICommand = new RelayCommand(
        () => {
            const isModelValid = this.isModelValid();
            if (isModelValid) {
                this.exportStudentProgressReport();
            }
        },
        () => !this.isExporting,
    );

    // #region Api Commands

    public resetApiClientErrorCommand = new RelayCommand(() => {
        this.apiClient.reset();
    });

    // #endregion Api Commands
    //#endregion

    //#region helpers
    private updateField(fieldName: keyof FieldType<ReportsModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public debouncedLoading = (callback: () => void, delayMs: number = 500) => {
        if (this.loadTimeoutId) {
            clearTimeout(this.loadTimeoutId);
        }

        this.setLoadTimeoutId(
            setTimeout(() => {
                callback();
            }, delayMs),
        );
    };
    //#endregion
}
