import styled from "@emotion/styled";

export const Container = styled.div`
    padding: 25px 20px;
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 200px repeat(3, 150px);
    column-gap: 30px;
    align-items: end;
    button {
        height: 40px;
    }
`;
