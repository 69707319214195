import styled from "@emotion/styled";
import { Box } from "Application";

interface Props {
    color?: string;
    backgroundColor?: string;
}

export const AttendanceField = styled(Box)`
    .form-control {
        input[type="checkbox"]:before {
            box-shadow: inset 1em 1em #e6054e;
        }
    }
`;

export const TargetList = styled(Box)`
    & > div:nth-of-type(odd) {
        background: #fff;
    }
`;

export const FormField = styled(Box)`
    .placeholder {
        font: italic normal normal 12px/16px Open Sans;
        letter-spacing: 0px;
        color: #000000;
    }
`;

export const NavigationBox = styled(Box)`
    align-items: center;
    background-color: #f3f3f3;
    cursor: pointer;
    display: flex;
    min-height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    cursor: pointer;
    gap: 10px;
    label {
        text-decoration: underline;
        font: normal normal normal 12px/16px Open Sans;
        letter-spacing: -0.12px;
        color: #000000;
    }
`;

export const CreateBox = styled(Box)`
    & > div .isButtonDisable {
        cursor: default;
        color: #495057;
        background-color: #e9ecef;
    }
`;

export const DetailsList = styled(Box)`
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`;

export const BreadCrumbContainer = styled.div`
    position: absolute;
    top: 50px;
    left: 20px;
`;

export const WeeklyRotaFilter = styled(Box)`
    grid-template-columns: 1fr 1fr 1fr 2fr 3fr 1fr;
    @media only screen and (max-width: 1440px) {
        grid-template-columns: 1fr 1fr 1fr 1.7fr 2.6fr;
    }
    @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        & > div:last-child {
            flex-wrap: wrap;
        }
    }
`;

export const TableList = styled(Box)``;

export const TableBox = styled(Box)<Props>`
    display: grid;
    grid-template-columns: 280px auto;
    @media only screen and (max-width: 767px) {
        grid-template-columns: 60px auto;
    }
    &:nth-of-type(2n) {
        background: #f9f9f9;
    }
    & > div:first-child {
        height: 100%;
        display: flex;
        & > label {
            padding: 0 15px;
            display: grid;
            grid-template-columns: 45px auto;
            gap: 0;
        }
    }
    input[type="checkbox"] {
        height: 30px;
        width: 30px;
        &::before {
            height: 16px;
            width: 18px;
            box-shadow: inset 1em 1em #bd9e17;
        }
    }
    label {
        font: normal normal bold 12px/16px Open Sans;
        letter-spacing: -0.12px;
    }
    .check-label {
        display: flex;
        align-items: center;
        border-left: 1px solid #dcdcdc;
        height: 100%;
        padding-left: 15px;
        @media only screen and (max-width: 767px) {
            display: none !important;
        }
        label {
            color: ${(p) => p.color};
        }
    }
    & > div:last-child {
        padding: 20px 10px;
        display: flex;
        justify-content: center;
        border-left: 1px solid #dcdcdc;
        flex-direction: column;
        gap: 2px;
        label {
            font: normal normal bold 12px/16px Open Sans;
            letter-spacing: -0.12px;
            &:first-child {
                display: none;
                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }
        }
        & > label:last-child {
            font: normal normal normal 12px/16px Open Sans;
            color: #000000;
        }
    }
`;

export const SelectBox = styled(Box)<Props>`
    max-width: 130px;
    .editselect {
        & > div:first-child {
            background: white;
        }
    }
    .editselect__control {
        background: ${(p) => p.backgroundColor};
    }
    .editselect__placeholder,
    .editselect__single-value {
        color: #fff;
        font: normal normal bold 12px/18px Open Sans;
        letter-spacing: 0px;
    }
`;

export const TargetCheckbox = styled(Box)`
    input[type="checkbox"] {
        height: 30px;
        width: 30px;
        &::before {
            height: 16px;
            width: 18px;
            box-shadow: inset 1em 1em #bd9e17;
        }
    }
`;

//not sure about the business logic of this component
//was moved to styled component
export const StatusBox = styled(Box)<{ hasId: boolean }>`
    font: normal normal bold 12px/18px Open Sans;
    letter-spacing: 0px;
    text-transform: uppercase;
    background: ${(props) => (props.hasId ? "#11A696" : "#C15DB0")};
    text-align: center;
    color: #fff;
    height: 30px;
    width: 140px;

    padding: 6px 25px;
`;

export const Container = styled.div`
    margin-top: 100px;
    padding-bottom: 100px;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 30px 30px 0 30px;
`;
