import "@emotion/react";
import { AccentColours, IShoothillTheme } from "./IShoothillTheme";
import chroma from "chroma-js";

export const pxToRem = (value: number) => {
    return `${value / 16}rem`;
};
export const adobeXDToRem = (fontSize: number, letterSpacing: number): string => {
    return pxToRem((letterSpacing / 1000) * fontSize) + "rem";
};

//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
let customBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};

const ThemeColours = (colour: string, text: string, scale = 0.2): AccentColours => {
    const retval = {
        lighter: chroma(colour)
            .brighten(scale * 2)
            .hex(),
        light: chroma(colour).brighten(scale).hex(),
        main: colour,
        dark: chroma(colour).darken(scale).hex(),
        darker: chroma(colour)
            .darken(scale * 2)
            .hex(),
        text: text,
    };
    /*if (chroma.contrast(retval.main, retval.text) < 4.5) {
        console.warn("Text may not be readable with this colour");
    }*/
    return retval;
};

export const StyleSheet1: IShoothillTheme = {
    // Spacing in pixels.
    space: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],

    // Sizes. Keep numbers divisible by 2.
    sizes: {
        larger: pxToRem(40),
        large: pxToRem(30),
        medium: pxToRem(26),
        small: pxToRem(20),
    },
    dates: {
        default: "DD/MM/YYYY",
        short: "DD/MM",
        long: "MMMM Do, YYYY",
    },
    fontStyles: {
        h1: {
            fontSize: pxToRem(24),
            letterSpacing: pxToRem(0),
            lineHeight: pxToRem(22),
            fontFamily: "OpenSans-CondBold",
        },
        h2: {
            fontSize: pxToRem(18),
            letterSpacing: pxToRem(0),
            lineHeight: pxToRem(22),
            fontFamily: "OpenSans-CondBold",
        },
        h3: {
            fontSize: pxToRem(14),
            letterSpacing: pxToRem(0),
            lineHeight: pxToRem(18),
            fontFamily: "OpenSans-CondBold",
            textTransform: "uppercase",
        },
        h4: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0),
            lineHeight: pxToRem(16),
            fontFamily: "OpenSans-CondBold",
            textTransform: "uppercase",
        },
        h5: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0.3),
            lineHeight: pxToRem(18),
            fontFamily: "OpenSans-Regular",
        },
        h6: {
            fontSize: pxToRem(10),
            letterSpacing: pxToRem(0.3),
            lineHeight: pxToRem(18),
            fontFamily: "OpenSans-Regular",
        },
        h7: {
            fontSize: pxToRem(10),
            fontWeight: 600,
            letterSpacing: "0rem",
            lineHeight: pxToRem(10),
        },
        error: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.15),
        },
        label: {
            fontSize: pxToRem(14),
            letterSpacing: pxToRem(0.7),
            lineHeight: pxToRem(18),
            fontFamily: "OpenSans-CondBold",
        },
        tableHeader: {
            fontSize: pxToRem(14),
            fontWeight: 700,
            letterSpacing: pxToRem(-0.18),
            lineHeight: "42px",
        },
        checkboxLabel: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0.3),
            lineHeight: pxToRem(18),
            fontFamily: "OpenSans-Regular",
        },
        radioButtonLabel: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.12),
            lineHeight: pxToRem(16),
            fontFamily: "OpenSans-Regular",
        },
        headerOptionLabel: {
            fontSize: pxToRem(14),
            letterSpacing: pxToRem(0),
            lineHeight: pxToRem(18),
            fontFamily: "OpenSans-CondBold",
            textTransform: "uppercase",
        },

        button: {
            fontSize: pxToRem(14),
            letterSpacing: pxToRem(0),
            lineHeight: pxToRem(18),
            fontFamily: "OpenSans-CondBold",
            textTransform: "uppercase",
        },
        p: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(0),
            lineHeight: pxToRem(18),
            fontFamily: "OpenSans-Regular",
        },

        field: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.12),
            lineHeight: pxToRem(17),
            fontFamily: "OpenSans-Regular",
        },
        link: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.12),
            lineHeight: pxToRem(16),
            fontFamily: "OpenSans-Regular",
            textDecoration: "underline",
        },
        placeholder: {
            fontSize: pxToRem(12),
            letterSpacing: pxToRem(-0.32),
            lineHeight: pxToRem(17),
            fontFamily: "OpenSans-Regular",
        },
    },
    palette: {
        // Use this for your default text.
        common: {
            transparent: "transparent",
            white: "#fff",
            black: "#000",
            // FluentUI focus color. Do not edit!
            focus: "#0078d4",
            // Off-black.
            default: "#707070",
            green: "#50AE41",
            purple: "#6679C0",
            blue: "#7CCBE1",
            darkPurple: "#25396F",
            lightYellow: "#FCD21D",
            darkerGrey: "#EDEDED",
            lightGrey: "#F9F9F9",
            // Yellow.
            primary: "#FCD21D",
            // Dark grey.
            secondary: "#575756",
            // Mid Blue.
            tertiary: "#2D91FF",

            quarternary: "#2D91FF",
            // Mid red
            error: "#E6054E",
            // Mid orange
            warning: "#DC3545",
            // Dark teal
            success: "#11A696",
            // Light teal
            success_light: "#27E6A3",
            // Purple
            info: "#C15DB0",
            // Ocher
            hint: "#BD9E17",
            //student status
            studentStatusLive: "#27E6A3",
            studentStatusPast: "#F39200",
            studentStatusEnquiry: "#71B3FC",
            studentStatusOutreach: "#71B3FC",
        },

        // Use these for your controls - those that have a visual state - focused, hover, pressed etc.
        // Light defines the default state with main and dark being non-default states.

        disabled: ThemeColours("#f3f2f1", "#a19f9d"),
        // Dark grey background with white text
        default: ThemeColours("#707070", "#fff"),
        // Yellow background with black text
        primary: { lighter: "#ffea8d", light: "#FCD21D", main: "#FCD21D", dark: "#FCD21D", darker: "#FCD21D", text: "#000" },
        // Dark grey with white text
        secondary: ThemeColours("#575756", "#fff"),
        // Mid Blue background with white text
        tertiary: { lighter: "#F0D6EB", light: "#F0D6EB", main: "#2D91FF", dark: "#2196f3", darker: "#2196f3", text: "#fff" },
        quarternary: { lighter: "#FFF", light: "#F3F3F3", main: "#D4D4D4", dark: "#2196f3", darker: "#2196f3", text: "#000" },
        // Mid red background with mid red text
        error: ThemeColours("#E6054E", "#fff"),
        // Mid orange background with mid red text
        warning: ThemeColours("#DC3545", "#fff"),
        // Purple background with mid red text
        info: { lighter: "#F0D6EB", light: "#F0D6EB", main: "#2196f3", dark: "#2196f3", darker: "#2196f3", text: "#fff" },
        // Teal with white text
        success: { lighter: "#C3E9E5", light: "#27E6A3", main: "#11A696", dark: "#11A696", darker: "#11A696", text: "#fff" },
        // Ochre with white text
        hint: { lighter: "#FFFBE8", light: "#FCE4BF", main: "#BD9E17", dark: "#BD9E17", darker: "#BD9E17", text: "#fff" },
        delete: ThemeColours("#c52f3e", "#fff"),

        //misc
        // Dark transparent background with off-black text
        defaultTr: ThemeColours("#f3f2f1", "#171716"),
        // Light grey border with dark grey for hover/focus and off-black text.
        field: { lighter: "rgb(198, 51, 255)", light: "rgb(198, 51, 255)", main: "#ABB7C2", dark: "rgb(128, 0, 178)", darker: "rgb(128, 0, 178)", text: "black" }, //This is a custom colour
        tableHeader: { lighter: "#FFF", light: "#F3F3F3", main: "#D4D4D4", dark: "#2196f3", darker: "#2196f3", text: "#000" },

        lightYellow: ThemeColours("#FCD21D", "#fff"),

        //region NOTE:
        //The styles below are for reference only
        //You can still define your colours manually if you want to
        purpleAccent: { lighter: "rgb(198, 51, 255)", light: "rgb(198, 51, 255)", main: "#b800ff", dark: "rgb(128, 0, 178)", darker: "rgb(128, 0, 178)", text: "pink" }, //This is a custom colour
        //endregion NOTE
    },

    // Fonts and Text.
    defaultFontStyle: {
        fontFamily: "OpenSans-Regular",
        fontSize: pxToRem(16),
        letterSpacing: "normal",
        lineHeight: "1.5",
        textTransform: "none",
    },
};
