import { useState, useEffect } from "react";

export default function useWindowDimensions() {
    const hasWindow = typeof window !== "undefined";

    function getWindowDimensions() {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return {
            width,
            height,
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                const windowDimensions = getWindowDimensions();
                setWindowDimensions(windowDimensions);
            }

            // Add both resize and orientationchange event listeners
            window.addEventListener("resize", handleResize);
            window.addEventListener("orientationchange", handleResize);

            return () => {
                window.removeEventListener("resize", handleResize);
                window.removeEventListener("orientationchange", handleResize);
            };
        }
    }, [hasWindow]);

    return windowDimensions;
}
